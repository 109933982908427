<template>
  <section>
    <v-row>
      <v-col cols="12" sm="6" class="">
        <span class="text-h5 secondary--text"> {{ titulo }}</span>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex justify-end">
        <span class="mr-10">Fecha de inicio: {{ fecha_inicio }} </span>
        <span>Fecha de fin: {{ fecha_final }} </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <BasesDelProcesoComponent v-if="idEtapa == 1" />
        <ConsultasComponent :is-uaci="true" v-if="idEtapa == 2" />
        <AdendasEnmiendasComponent
          :isUACI="true"
          :idProceso="idProceso"
          v-if="idEtapa == 3"
        />
        <RecepcionOfertaComponent
          v-if="idEtapa == 4"
          :fecha_final="fecha_final"
        />
        <AperturaOfertaComponent v-if="idEtapa == 5" :isUaci="true" :idProceso="idProceso" />
        <EvaluacionRecursosRevisionComponent :isUaci="true" :idProceso="idProceso" v-if="idEtapa == 7 || idEtapa == 12" />
        <SubastaInversaComponent v-if="idEtapa == 9" />
        <Resultados :isUaci="true" v-if="idEtapa == 10 || idEtapa == 13" :idEtapa="idEtapa" />
        <RecursoRevisionComponent
          v-if="idEtapa == 11"
          :idProceso="idProceso"
          :isUaci="true"
        />
        <!-- Se reutiliza el componente de etapas preliminares -->
        <!-- <ResultadosFinalesComponent v-if="idEtapa == 12" /> -->
        <ContratacionComponent v-if="idEtapa == 14" :idProceso="idProceso"/>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import moment from "moment";
import BasesDelProcesoComponent from "./EtapaBasesDelProcesoUACIComponent.vue";
import EvaluacionOfertasComponent from "./EtapaEvaluacionOfertasUACIComponent.vue";
import AdendasEnmiendasComponent from "./EtapaAdendasEnmiendasUACIComponent.vue";
import RecepcionOfertaComponent from "./EtapaRecepcionOfertaUACIComponent.vue";
import SubastaInversaComponent from "./EtapaSubastaInversaUACIComponent.vue";
import ConsultasComponent from "./EtapaConsultasUACIComponent.vue";
import AperturaOfertaComponent from "./EtapaAperturaOfertaUACIComponent.vue";
// Resultados preliminares y finales se reutilizan los mismos componentes
import Resultados from "./EtapaResultadosPreliminaresUACIComponent.vue";
import ContratacionComponent from "./EtapaContratacionUACIComponent.vue";
import RecursoRevisionComponent from "./EtapaRecursoRevisionUACIComponent.vue";
import EvaluacionRecursosRevisionComponent from "./EtapaEvaluacionRecursosRevisionUACIComponent.vue";
import { mapActions, mapMutations } from "vuex";
export default {
  components: {
    BasesDelProcesoComponent,
    EvaluacionOfertasComponent,
    AdendasEnmiendasComponent,
    RecepcionOfertaComponent,
    SubastaInversaComponent,
    ConsultasComponent,
    AperturaOfertaComponent,
    Resultados,
    ContratacionComponent,
    RecursoRevisionComponent,
    EvaluacionRecursosRevisionComponent,
  },
  name: "EtapaActualPaacUaci",
  data: () => ({
    titulo: "Bases del Proceso",
    fecha_inicio: "01/01/2021",
    fecha_final: "01/01/2021",
    idEtapa: null,
    idProceso: null,
  }),
  methods: {
    ...mapMutations("etapaSubastaInversaStore", ["setSalas"]),
    ...mapActions("etapaConsultasStore", [
      "setConsultas",
      "setIdProcesoCompra",
    ]),
    ...mapActions("etapaResultadosPreliminares", [
      "setIdProcesoResultadosPreliminares",
    ]),
    ...mapMutations("etapaAperturaOfertaStore", ["setListaAperturaOferta"]),
    ...mapMutations("etapaRecepcionOfertasUaci", ["setFechaFinal"]),
    ...mapMutations("etapaBaseProcesoUaci", ["setListDocumentosBase"]),

    ...mapMutations("paacProveedor", ["setIdProceso"]),
    handlerTypeVuex(data) {

      if(data.id_etapa == undefined && data.etapa_vigente == false) {
        this.temporalAlert({
            show: true,
            type: "error",
            message:
              " La etapa actual no se encuentra vigente",
          });
      }else {
        switch (data.id_etapa) {
        case 1:
          this.setListDocumentosBase(data?.documentos);
          break;
        case 2:
          this.setConsultas(data.consultas);
          this.setIdProcesoCompra(this.idProceso);
          break;
        case 3:
          break;
        case 4:
          this.setFechaFinal(data?.fecha_hora_fin);
          break;
        case 5:
          this.setListaAperturaOferta(data.data);
          break;
        case 6:
          break;
        case 7:
          break;
        case 8:
          break;
        case 9:
          this.setSalas(data.salas);
          break;
        case 10:
          this.setIdProcesoResultadosPreliminares(this.idProceso);
          break;
        case 11:
          break;
        case 12:
          this.setIdProcesoResultadosPreliminares(this.idProceso);
          break;
        case 13:
          this.setIdProceso(data.id);
          this.setIdProcesoResultadosPreliminares(this.idProceso);
          break;
        case 14:
          this.setIdProceso(data.id);
          break;
        default:
          this.temporalAlert({
            show: true,
            type: "error",
            message:
              " No se ha encontrado la etapa, por favor contacte al administrador del sistema",
          });
          break;
      }
      }
    },
    async obtainEtapaActual() {
      // Crear una nuevo método en paac services que utilice el mismo endpoint para obtener la etapa actual
      // Y mandarle los parámetros de paginación, y utilizar dentro de cada componente que requiera paginar
      // y no modificar el que se ejecuta en este componente porque se rompe retorna 500 cuando es una etapa diferente
      // let params = {
      //   pagination: true,
      //   page: 1,
      //   per_page: 5,
      // };
      const response = await this.services.Paac.getEtapaActual(this.idProceso);
      if (response) {

        this.titulo = response.data.nombre;
        this.fecha_inicio = moment(response.data.fecha_hora_inicio).format(
          "DD/MM/YYYY HH:mm"
        );
        this.fecha_final = moment(response.data.fecha_hora_fin).format(
          "DD/MM/YYYY HH:mm"
        );
        this.idEtapa = response.data.id_etapa;
        this.handlerTypeVuex(response.data);
      }
    },
  },
  created() {
    this.idProceso = this.$route.params.id;
    this.obtainEtapaActual();
  },
};
</script>

<style scoped>
.backgroundColor {
  background-color: #f4f7fd;
}
</style>
