<template>
  <section>
    <v-row>
      <v-col cols="12" sm="8">
        <app-countdown
          :dateNow="moment()"
          :nextDay="moment(fechaFinal)"
        />
      </v-col>
      <v-col cols="12" sm="8">
        <p>{{ description }}</p>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "Recepcion",
  data: () => ({
    description:
      "Durante este Período los proveedores cargaran sus ofertas, las cuales no podrán abrirse hasta la siguiente estapa.",
  }),

  computed: {
    ...mapState('etapaRecepcionOfertasUaci', ['fechaFinal'])
  },
};
</script>

<style></style>
