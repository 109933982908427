<template>
  <section class="ml-3">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="6">
            <v-breadcrumbs large :items="breadcrumbsItems"></v-breadcrumbs>
          </v-col>
          <v-spacer></v-spacer>

          <!-- Habilitar cuando el proceso este publicado -->

          <!-- Deshabilitar cuando el proceso este publicado-->
          <v-col
            cols="12"
            sm="3"
            md="2"
            v-if="info_general.id_seguimiento_proceso == 1"
          >
            <v-btn
              block
              color="secondary"
              class="bgWhite--text"
              @click="mostrarModalPublicar"
            >
              Publicar
            </v-btn>
          </v-col>
          <v-col
            v-if="false"
            cols="12"
            sm="6"
            :class="$vuetify.breakpoint.xs ? '' : 'd-flex justify-end'"
          >
            <v-btn text color="btnRed" :block="blockButton">
              <v-icon color="btnRed">mdi-stop</v-icon> Detener proceso</v-btn
            >
            <v-btn text color="btnRed" :block="blockButton">
              <v-icon color="btnRed">mdi-cancel</v-icon> Anular proceso</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="1" sm="2">
            <v-img width="100px" src="@/assets/img/logo_512_negro.png"></v-img>
          </v-col>
          <v-col cols="12" md="10" sm="8">
            <v-row>
              <v-col v-if="!editar_nombre" cols="4">
                <span>{{ shortText }}</span>
                <v-icon class="ml-5" @click="mostrarEditarNombre">
                  mdi-pencil</v-icon
                >
              </v-col>
              <v-col cols="4" v-else>
                <v-text-field
                  @change="modificarNombreDelProceso"
                  v-model="info_general.nombre_proceso"
                  label="Nombre del proceso"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col v-if="editar_nombre" cols="1">
                <v-icon class="ml-5" @click="mostrarEditarNombre">
                  mdi-pencil</v-icon
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <app-tabs
          v-if="info_general.id_seguimiento_proceso != null"
          :contenido="contenido"
          :items="tabs"
          :id="Number(id)"
        />
      </v-col>
    </v-row>

    <!-- Modal para publicar proceso -->
    <v-dialog v-model="dialog" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline"
          >¿Desea publicar el proceso?</v-card-title
        >
        <v-card-text>
          <p>
            El proceso de compra estará disponible para que los proveedores
            puedan inscribirse en el proceso.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            class="ml-5 mb-5"
            outlined
            @click="dialog = false"
            :disabled="publicar_loading"
            >Cerrar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="publicar_loading"
            class="mr-5 mb-5"
            @click="publicarProceso"
            >Publicar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import generalView from "./components/GeneralComponent.vue";
import documentosProceso from "./components/DocumentosProcesoComponent.vue";
import estapasProceso from "./components/EtapasProcesoComponent.vue";
import EtapaActualComponent from "./components/etapas/EtapaActuaContainerComponent.vue";
import InvitarParticipanteComponent from "./components/InvitarParticipanteComponent.vue";
import ConfiguracionPEOComponent from "./components/ConfiguracionPEOComponent.vue";
import SolicitudesAsignadasComponent from "./components/SolicitudesAsignadasComponent.vue";
export default {
  name: "ConfiguracionProceso",
  components: {
    generalView,
    documentosProceso,
    estapasProceso,
    EtapaActualComponent,
    ConfiguracionPEOComponent,
    SolicitudesAsignadasComponent
  },
  data: () => ({
    dialog: null,
    id: null,
    tabsMenu: null,
    editar_nombre: false,
  }),
  computed: {
    ...mapState("procesoCompra", ["info_general", "publicar_loading"]),
    shortText() {
      let text = this.info_general.nombre_proceso;

      if (this.$vuetify.breakpoint.xs) {
        return text.slice(0, 30) + "...";
      } else return text;
    },
    blockButton() {
      if (this.$vuetify.breakpoint.xs) {
        return true;
      } else return false;
    },
    // Propiedades computadas para renderizar las tabs y su contenido
    tabs() {
      return [
        {
          text: "Etapa Actual",
          icono: "mdi-flag-triangle",
          hide: this.info_general?.id_seguimiento_proceso == 2 ? false : true,
        },
        {
          text: "General",
          icono: "mdi-clipboard-text",
        },
        {
          text: "Documentos del Proceso",
          icono: "mdi-book-open",
        },
        {
          text: "Etapas del Proceso",
          icono: "mdi-format-list-numbered",
        },
        {
          text: "Solicitudes asignadas",
          icono: "mdi-clipboard-list-outline",
        },
        {
          text: "PEO",
          icono: "mdi-card-account-details-outline",
          hide: this.info_general?.id_forma_contratacion == 3,

        },
        {
          text: "Participantes",
          icono: "mdi-format-list-numbered",
          hide: this.ocultarPestaniaParticipantes,
        },
      ];
    },
    contenido() {
      return [
        {
          tab: "Etapa Actual",
          content: EtapaActualComponent,
          show: false,
          hide: this.info_general.id_seguimiento_proceso == 2 ? false : true,
        },
        {
          tab: "General",
          content: generalView,
        },
        {
          tab: "Documentos del Proceso",
          content: documentosProceso,
        },
        {
          tab: "Etapas del Proceso",
          content: estapasProceso,
        },
        {
          tab: "Solicitudes asignadas",
          content: SolicitudesAsignadasComponent,
        },
        {
          tab: "PEO",
          content: ConfiguracionPEOComponent,
          hide:  this.info_general?.id_forma_contratacion == 3,
        },
        {
          tab: "Participantes",
          content: InvitarParticipanteComponent,
          hide: this.ocultarPestaniaParticipantes,
        },
      ];
    },
    breadcrumbsItems() {
      return [
        {
          text: "Proceso",
          disable: true,
          href: "/paac-proceso-listado",
        },
        {
          text: this.info_general.codigo_proceso,
          disable: true,
          href: "#",
        },
      ];
    },
    /**
     * Oculta la pestaña de participantes si el tipo de proceso es diferente a
     * compra directa o libre gestión, o si el proceso no esta publicado
     *
     * @returns {Boolean}
     */
    ocultarPestaniaParticipantes() {
      if (
        this.info_general?.id_forma_contratacion == 2 ||
        this.info_general?.id_forma_contratacion == 4 ||
        this.info_general?.id_seguimiento_proceso == 2
      ) {
        return false;
      } else return true;
    },
  },
  methods: {
    ...mapActions("procesoCompra", [
      "getInfoGeneralProcesoCompra",
      "putInfoGeneralProcesoCompra",
      "publicarProcesoCompra",
    ]),
    ...mapMutations("procesoCompra", ["setPublicarLoading"]),
    async publicarProceso() {
      const response = await this.publicarProcesoCompra().catch(() => {
        this.setPublicarLoading(false);
        this.dialog = false;
      });
      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          message: response.data.message,
          type: "success",
        });
        this.dialog = false;
      }
    },
    mostrarEditarNombre() {
      this.editar_nombre = !this.editar_nombre;
    },
    modificarNombreDelProceso() {
      const data = {
        id: this.id,
        params: {
          nombre_proceso: this.info_general.nombre_proceso,
        },
      };

      this.putInfoGeneralProcesoCompra(data);
      this.editar_nombre = false;
    },
    mostrarModalPublicar() {
      this.dialog = true;
    },
    async publicarProcesoPaacAction() {
      this.loading = true;
      const response = await this.publicarProcesoCompra(data);
      if (response) {
        this.temporalAlert({
          show: true,
          message: response.data?.message,
          type: "success",
        });
      }
      this.loading = false;
    },
  },
  async created() {
    if (this.$route.params.id !== null || this.$route.params.id !== undefined) {
      this.id = this.$route.params.id;
      await this.getInfoGeneralProcesoCompra(this.id);
      //force refresh a app-tabs
    }
  },
};
</script>

<style></style>
