<template>
  <section class="backgroundColor mb-8">
    <v-row class="pt-5 pr-3">
      <v-col cols="12" sm="3">
        <p>Forma de contratación</p>
        <p class="text-h5">{{ info_general.forma_contratacion }}</p>
        <p>Código</p>
        <p class="text-h5">{{ info_general.codigo_proceso }}</p>
      </v-col>
      <v-col cols="12" sm="9">
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              label="Nombre proceso"
              v-model="info_general.nombre_proceso"
              @change="
                editarInformacionGeneral(
                  'nombre_proceso',
                  info_general.nombre_proceso
                )
              "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="lista_tecnico"
              item-text="nombre_completo_tecnico"
              item-value="id"
              outlined
              label="Técnico asignado"
              v-model="info_general.tecnico"
              @change="
                editarInformacionGeneral(
                  'id_empleado_unidad',
                  info_general.tecnico
                )
              "
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              label="Número interno del proceso"
              v-model="info_general.numero_proceso"
              @change="
                editarInformacionGeneral(
                  'numero_interno',
                  info_general.numero_proceso
                )
              "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <p class="mb-0">Inicio del proceso</p>
            <v-date-picker
              v-model="info_general.fecha_inicio"
              type="month"
              locale="es-Es"
              header-color="#404a7a"
              style="margin-right: 40px; margin-top: 15px"
              :min="moment().format('YYYY-MM-DD')"
              @change="
                editarInformacionGeneral(
                  'fecha_inicio',
                  info_general.fecha_inicio
                )
              "
            />
          </v-col>
          <v-col cols="12" sm="4">
            <p class="mb-0">Finalización del proceso</p>
            <v-date-picker
              :min="info_general.fecha_inicio"
              :disabled="info_general.fecha_inicio == ''"
              v-model="info_general.fecha_fin"
              type="month"
              locale="es-Es"
              style="margin-top: 15px; color-text: white"
              header-color="#404a7a"
              @change="
                editarInformacionGeneral('fecha_fin', info_general.fecha_fin)
              "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              outlined
              label="Justificación de la compra"
              v-model="info_general.justificacion_compra"
              @change="
                editarInformacionGeneral(
                  'justificacion',
                  info_general.justificacion_compra
                )
              "
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pb-5">
            <DataTable
              :headers="headers"
              :items="lista_administradores"
              :tiene_paginacion="false"
            >
            </DataTable>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/DataTableComponent.vue";
import moment from "moment";
export default {
  name: "ConfiguracionProceso",
  props: ["id"],
  components: {
    DataTable,
  },
  data: () => ({
    info_general: {
      tecnico: null,
      numero_proceso: null,
      justificacion_compra: null,
      forma_contratacion: null,
      codigo_proceso: null,
      nombre_proceso: null,
      fecha_inicio: null,
      fecha_fin: null,
    },
    headers: [
      {
        text: "Administrador de Contrato",
        align: "center",
        sortable: true,
        value: "nombre_completo_tecnico",
      },
      {
        text: "Unidad",
        align: "center",
        sortable: true,
        value: "nombre_unidad",
      },
    ],
  }),
  computed: {
    ...mapState("procesoCompra", ["lista_tecnico", "lista_administradores"]),
  },
  methods: {
    ...mapActions("procesoCompra", [
      "getListaTecnicosProcesoCompra",
      "getInfoGeneralProcesoCompra",
      "putInfoGeneralProcesoCompra",
    ]),
    async getInformacionGeneral(id_proceso_compra) {
      const response = await this.getInfoGeneralProcesoCompra(
        id_proceso_compra
      );
      if (response.status === 200) {
        this.info_general.tecnico = response.data.id_empleado_tecnico;
        this.info_general.nombre_proceso = response.data.nombre_proceso;
        this.info_general.numero_proceso = response.data.codigo_interno;
        this.info_general.justificacion_compra =
          response.data.justificacion_compra;
        this.info_general.forma_contratacion =
          response.data.nombre_forma_contratacion;
        this.info_general.codigo_proceso = response.data.codigo_proceso;
        this.info_general.fecha_inicio = moment(
          response.data.fecha_inicio_proceso
        ).format("YYYY-MM");
        this.info_general.fecha_fin = moment(
          response.data.fecha_contratacion
        ).format("YYYY-MM");
      } else {
        this.termporalAlert({
          show: true,
          message: "No se pudo cargar la información",
          type: "error",
        });
      }
    },
    async editarInformacionGeneral(property, value) {
      let data = {
        id: this.id,
        params: {},
      };
      data.params[property] = value;
      if (property == "fecha_inicio")
        data.params[property] = this.moment(value)
          .startOf("month")
          .format("DD-MM-YYYY");
      if (property == "fecha_fin")
        data.params[property] = this.moment(value)
          .startOf("month")
          .format("DD-MM-YYYY");

      const response = await this.putInfoGeneralProcesoCompra(data);
      if (response.status === 200) {
        await this.getInformacionGeneral(this.id);
      }
    },
  },
  async created() {
    await this.getListaTecnicosProcesoCompra(this.id);
    await this.getInformacionGeneral(this.id);
  },
};
</script>

<style scoped>
.backgroundColor {
  background-color: #f4f7fd;
}
</style>
