<template>
  <section>
    <!-- NOTA: Este componente es similar 
         al del proceso desde el lado del proveedor 
         considere reutilizarlo si el funcionamiento no cambia
    -->
    <v-row class="mb-10">
      <v-col cols="12">
        <data-table-component 
          :headers="headers" 
          :items="listDocumentosBase"
          :tiene_paginacion="false"
        >
          <template #[`item.fecha_carga`]="{ item }">
            {{ moment(item.fecha_carga).format('DD/MM/YYYY') }}
          </template>
          <template v-slot:[`item.versiones`]="{ item }">
            <v-icon
              color="secondary"
              @click="descargarDocumentro(item)"
            >
              mdi-download
            </v-icon>
          </template>
        </data-table-component>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import PaacServices from '@/services/Paac.services';

import { mapMutations, mapState } from "vuex"
export default {
  name: "EtapaBasesDelProcesoUACIComponent",
  components: {
    DataTableComponent,
  },
  data() {
    // Headers de la tabla, Documento, Titulo, Fecha de carga, Versiones (Tendrá un botón para descargar)
    return {
      headers: [
        { text: "Documento", value: "nombre_documento" },
        { text: "Tipo de documento", value: "tipo_documento" },
        { text: "Fecha de carga", value: "fecha_carga" },
        { text: "Versiones", value: "versiones" },
      ],
    };
  },
  
  computed: {
    ...mapState('etapaBaseProcesoUaci', ['listDocumentosBase'])
  },

  methods: {

    // async obtenerDocumentosBase () {
    //   await this.services.Paac.getEtapaActual(this.$route.params.id_proceso_compra, this.filtros);
    // },

    async descargarDocumentro(item){
      const response = await PaacServices.downloadDocumentoProcesoCompra(item.ruta_documento)
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", `${item.nombre_documento}`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
  }

};
</script>
<style></style>
