<template>
  <section>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              label="Buscar proveedor"
              v-model="search"
              placeholder="Buscar"
              outlined
              clearable
              @change="searchProveedor"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <DataTable
          v-models:select="perPageModel"
          v-models:pagina="pageModel"
          :total_registros="total_rows"
          @paginar="paginar"
          :headers="headers"
          :items="items"
        >
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip
              label
              :color="item.color"
              :class="item.id_estado === 2 ? 'white--text' : ''"
            >
              {{ item.estado }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              v-if="item.id_estado == 1 && item.id_contrato === null"
              dark
              color="#404a7a"
              @click="crearContrato(item)"
            >
              Contrato
            </v-btn>
            <v-btn
              v-if="item.id_estado == 2 || item.id_contrato > 0"
              dark
              color="#404a7a"
              @click="crearContrato(item)"
            >
              <v-icon color="white">mdi-eye</v-icon>
            </v-btn>
          </template>
        </DataTable>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapMutations } from "vuex";
import DataTable from "@/components/DataTableComponent.vue";
export default {
  components: {
    DataTable,
  },
  props: {
    idProceso: {
      type: String,
    },
  },
  data: () => ({
    search: null,
    value: false,
    items: [],
    filters: {
      pagination: true,
      page: 1,
      per_page: 10,
      search: null,
    },
    total_rows: null,
    headers: [
      { text: "Proveedor", value: "proveedor", width: "60%" },
      { text: "Estado", value: "estado" },
      { text: "Acciones", align: "center", value: "actions" },
    ],
  }),

  computed: {
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.filters.page = value;
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.filters.per_page = value;
      },
    },
  },

  methods: {
    ...mapMutations("agregarInsumo", ["setCurrentProvider", "setProviderInfo"]),
    ...mapMutations("procesoCompra", [
      "setIdOrdenCompra",
      "setContratoExistente",
    ]),
    async paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      const response = await this.services.Paac.getEtapaActual(
        this.idProceso,
        this.filters
      );
      this.total_rows = Number(response?.headers?.total_rows);
      this.items = response?.data?.data || [];
    },
    async getEtapaActualcreated() {
      const { status, headers, data } = await this.services.Paac.getEtapaActual(
        this.idProceso,
        this.filters
      );

      if (status === 200) {
        this.total_rows = Number(headers?.total_rows);
        this.filters.page = Number(headers?.page);
        this.filters.per_page = Number(headers?.per_page);
        this.items = data?.data;
      }
    },
    setPaginationData(payload) {
      this.filters.page = Number(payload.page);
      this.filters.per_page = Number(payload.per_page);
      this.total_rows = Number(payload.total_rows);
    },
    async crearContrato(item) {
      if (item.id_estado === 1) {
        let response =
          await this.services.ContratoService.getGanadorProveedorInfo(
            item.id_ganador_obs
          );
        if (response?.status == 200) {
          response.data.id_ganador = item.id_ganador_obs;
          this.setCurrentProvider(item.id_proveedor);
          this.setProviderInfo(response?.data);

          const { status, data } =
            await this.services.ContratoService.getContratoGanador(
              item.id_ganador_obs
            );
          if (status === 200 && data) {
            if (item.id_estado === 1) {
              this.setContratoExistente(data);
            }
          }

          this.$router.push({
            name: "crear-contrato-compra",
            params: { tipo: item.id_tipo_solicitud },
          });
        }
      } else {
        this.$router.push({
          name: "seguimiento-orden-compra",
          params: { idContrato: item.id_contrato },
        });
      }
    },
    async searchProveedor() {
      this.filters.search = this.search || null;
      const response = await this.services.Paac.getEtapaActual(
        this.idProceso,
        this.filters
      );
      this.items = response?.data?.data || [];
    },
  },
  async created() {
    await this.getEtapaActualcreated();
  },
};
</script>
