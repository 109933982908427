<template>
  <section fluid>
    <v-row class="py-0 my-0">
      <v-col cols="12" sm="3" md="3">
        <v-btn dark block color="secondary" @click="abrirModal()"
          >Agregar solicitudes</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      class="mt-4"
      :headers="encabezado2"
      :items="listadoObs"
      hide-default-footer
    >
      <template #[`item.monto_asignado`]="{ item }">
        {{
          item.monto_asignado
            ? Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(item.monto_asignado)
            : Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(0)
        }}
      </template>
      <template #[`item.accion`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="removeSolicitud(item)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Eliminar solicitud</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="modalAgregar" max-width="85vw" scrollable>
      <v-card class="py-6 px-6">
        <div class="justify-center d-flex">
          <v-col cols="3"
            ><span class="text-h6 secondary--text"
              >Agregar solicitudes</span
            ></v-col
          >
          <v-col cols="6" class="text-center"
            >$
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(total)
            }}</v-col
          >
          <v-col cols="3">
            <v-btn block color="secondary" @click="modalAgregar = false"
              >Finalizar</v-btn
            >
          </v-col>
        </div>

        <v-card-text class="py-4">
          <v-row class="mt-4">
            <v-col cols="6">
              <v-autocomplete
                label="Unidad solicitante"
                outlined
                v-model="form.id_unidad"
                :items="unidades"
                item-value="id"
                item-text="nombre"
                no-data-text="Sin coincidencias"
                clearable
                @change="getSolicitudesPendientes()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :filter="filterObsSelect"
                :items="obsList"
                :loading="isLoading"
                :maxLength="200"
                @keyup="isLoading = true"
                @update:search-input="searchObsSelect"
                clearable
                hide-no-data
                item-text="codigoNombre"
                item-value="id"
                label="Digitar OBS *"
                outlined
                placeholder="Escriba el nombre o código del OBS y luego selecciónelo *"
                return-object
                v-model="obsselected"
              />
            </v-col>
          </v-row>

          <data-table-component
            class="mb-4"
            :headers="encabezado"
            no-data-text="No hay datos"
            :items="solicitudes"
            v-models:select="perPageModel"
            v-models:pagina="pageModel"
            :total_registros="filters.total_rows"
            @paginar="paginar"
          >
            <template #[`item.check`]="{ item }">
              <v-btn
                icon
                v-if="!listadoObsIds.includes(item.id)"
                @click="addSolicitud(item)"
              >
                <v-icon color="secondary">mdi-plus</v-icon>
              </v-btn>
              <v-btn icon v-else @click="removeSolicitud(item)">
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <template #[`item.monto_asignado`]="{ item }">
              {{
                item.monto_asignado
                  ? Intl.NumberFormat("en-US", {
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(item.monto_asignado)
                  : Intl.NumberFormat("en-US", {
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(0)
              }}
            </template>
          </data-table-component>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import DataTableComponent from "../../../components/DataTableComponent.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "SolicitudesAsignadasComponent",
  components: {
    DataTableComponent,
  },
  data: () => ({
    modalAgregar: false,
    isLoading: false,
    encabezado: [
      {
        align: "center",
        value: "check",
      },
      {
        text: "OBS",
        value: "obs",
        align: "start",
      },
      {
        text: "Unidad de medida",
        value: "unidad_medida",
        align: "start",
      },
      {
        text: "Unidad",
        value: "unidad",
        align: "start",
      },
      {
        text: "Precio unitario($)",
        value: "precio_unitario",
        align: "center",
      },
      {
        text: "Cantidad",
        value: "cantidad",
        align: "cantidad",
      },
    ],
    encabezado2: [
      {
        text: "OBS",
        value: "obs",
        align: "start",
      },
      {
        text: "Unidad de medida",
        value: "unidad_medida",
        align: "start",
      },
      {
        text: "Unidad",
        value: "unidad",
        align: "start",
      },
      {
        text: "Monto ($)",
        value: "monto_obs",
        align: "center",
      },
      {
        text: "Cantidad",
        value: "total_obs",
        align: "center",
      },
      {
        text: "Acciones",
        value: "accion",
        align: "center",
      },
    ],
    obs_timer: null,
    obsselected: null,
    solicitudes: [],
    unidades: [],
    check: false,
    pagination: false,
    obsList: [],
    selected: [],
    listadoObs: [],
    listadoObsIds: [],
    form: {
      id_modalidad: null,
    },
  }),
  computed: {
    ...mapState(["anioFiscal"]),
    ...mapState("pac", ["filters"]),
    ...mapState(["selectedUnidad", "anioFiscal"]),
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setPage(value);
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setPerPage(value);
      },
    },
    total() {
      let suma = 0;
      this.listadoObs.forEach((proceso) => {
        suma += Number(proceso.monto_obs);
      });
      return suma;
    },
  },
  methods: {
    ...mapMutations("pac", ["setFiltros", "setFiltros2", "setPage"]),
    async getSolicitudesPendientes() {
      if (this.form.id_unidad || this.obsselected) {
        this.setPage(1);
        const response =
          await this.services.PacProcesos.getSolicitudesParaAsignar(
            this.$route.params.id,
            {
              ...this.filters,
              id_obs: this.obsselected,
              id_unidad: this.form.id_unidad,
            }
          );
        this.solicitudes = response?.data;
        this.filters.page = Number(response.headers.page);
        this.filters.per_page = Number(response.headers.per_page);
        this.filters.total_rows = Number(response.headers.total_rows);
      } else {
        const response2 =
          await this.services.PacProcesos.getSolicitudesParaAsignar(
            this.$route.params.id,
            {
              ...this.filters,
            }
          );
        this.solicitudes = response2?.data;
        this.filters.page = Number(response2.headers.page);
        this.filters.per_page = Number(response2.headers.per_page);
        this.filters.total_rows = Number(response2.headers.total_rows);
      }
    },
    abrirModal() {
      this.modalAgregar = true;
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getSolicitudesPendientes();
    },
    async getUnidades() {
      const response = await this.services.UnidadesServices.getAllUnidades();
      this.unidades = response.data;
    },
    async addSolicitud(item) {
      const { status } = await this.services.PacProcesos.asignarSolicitudesPAC(
        this.$route.params.id,
        item.id_solicitud_compra_obs
      );
      if (status == 201) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Solicitud agregada con éxito",
        });
        this.getSolicitudes();
        this.getSolicitudesPendientes();
      }
    },

    async removeSolicitud(item) {
      const { status } = await this.services.PacProcesos.eliminarSolicitudesPAC(
        this.$route.params.id,
        item.id_solicitud_compra_obs,
        item.id ?? item.id_detalle_solicitud
      );

      if (status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Solicitud eliminada con éxito",
        });
        this.getSolicitudes();
        this.getSolicitudesPendientes();

      }
    },
    filterObsSelect(item, queryText, itemText) {
      // crear array de palabras a buscar
      const words = queryText.split(" ");
      // crear array de palabras del item
      const hasWords = itemText.split(" ");

      // retornar valores que coincidan con algunas de las palabras a buscar
      return words.some((word) => {
        return hasWords.some((hasWord) => {
          return hasWord.toLowerCase().indexOf(word.toLowerCase()) > -1;
        });
      });
    },
    async searchObsSelect(value) {
      if (this.obs_timer) {
        clearTimeout(this.obs_timer);
      }

      this.obs_timer = setTimeout(async () => {
        let params = {
          search: value,
          id_tipo_obs: 4,
        };

        let codigoObs = Number(value);

        if (
          (codigoObs && codigoObs != NaN && value?.length >= 2) ||
          (value?.length > 2 && isNaN(codigoObs))
        ) {
          const { data } =
            await this.services.LineasTrabajos.getObjetoPresupuestario(params);
          const elements = [];
          data.forEach((obs) => {
            elements.push({
              ...obs,
              codigoNombre: `${obs.codigo} - ${obs.nombre}`,
            });
          });
          this.obsList = elements;
        }
        this.isLoading = false;
      }, 500);
    },

    async getSolicitudes() {
      const { status, data } =
        await this.services.PacProcesos.getSolicitudesAsignadasPAC(
          this.$route.params.id
        );

      if (status == 200) {
        this.listadoObs = data;
        this.listadoObsIds = data.map((col) => col.id_detalle_solicitud);
      }
    },
  },
  created() {
    this.getUnidades();
    this.getSolicitudes();
    this.getSolicitudesPendientes();
  },
};
</script>
