<template>
  <section>
    <v-row>
      <v-col cols="12" sm="3">
        <v-file-input
          outlined
          prepend-icon=""
          label="Acta de comité evaluador"
          accept="application/pdf"
          prepend-inner-icon="mdi-paperclip"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field label="Especificación" outlined append-icon="mdi-magnify">
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-autocomplete
          outlined
          label="Proveedor"
          placeholder="Selecione"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4">
        <v-autocomplete
          outlined
          label="OBS"
          placeholder="Selecione"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <DataTable
          :headers="headers"
          :items="items"
          :actions="actions"
          :show_actions="true"
          @listarOfertas="listarOfertas"
        >
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip
              label
              small
              :color="item.estado == 'Pendiente' ? '#fee8a6' : '#57cc99'"
            >
              {{ item.estado }}</v-chip
            >
          </template>
        </DataTable>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";

export default {
  name: "EvaluacionPaacUaci",
  components: {
    DataTable,
  },
  data: () => ({
    headers: [
      {
        text: "OBS",
        align: "start",
        width: "75%",
        sortable: true,
        value: "obs",
      },
      {
        text: "Estado",
        align: "start",
        sortable: true,
        value: "estado",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: true,
        value: "actions",
      },
    ],
    items: [
      {
        obs: "Papel Bond Blanco",
        estado: "Pendiente",
      },
      {
        obs: "Papel Bond Blanco",
        estado: "Completada",
        unidadMedida: "12",
      },
    ],
    actions: [
      {
        tooltip: {
          text: "Listar ofertas",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-clipboard-check-outline",
        },
        eventName: "listarOfertas",
      },
    ],
  }),
  methods: {
    listarOfertas(item) {
      this.$router.push({
        name: "listar-ofertas-subprocesos-paac",
        params: { id_obs: 1 },
      });
    },
  },
};
</script>

<style></style>
