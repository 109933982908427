var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"backgroundColor mb-8"},[_c('v-row',{staticClass:"pt-5 pr-3"},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('p',[_vm._v("Forma de contratación")]),_c('p',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.info_general.forma_contratacion))]),_c('p',[_vm._v("Código")]),_c('p',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.info_general.codigo_proceso))])]),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Nombre proceso"},on:{"change":function($event){return _vm.editarInformacionGeneral(
                'nombre_proceso',
                _vm.info_general.nombre_proceso
              )}},model:{value:(_vm.info_general.nombre_proceso),callback:function ($$v) {_vm.$set(_vm.info_general, "nombre_proceso", $$v)},expression:"info_general.nombre_proceso"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.lista_tecnico,"item-text":"nombre_completo_tecnico","item-value":"id","outlined":"","label":"Técnico asignado"},on:{"change":function($event){return _vm.editarInformacionGeneral(
                'id_empleado_unidad',
                _vm.info_general.tecnico
              )}},model:{value:(_vm.info_general.tecnico),callback:function ($$v) {_vm.$set(_vm.info_general, "tecnico", $$v)},expression:"info_general.tecnico"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Número interno del proceso"},on:{"change":function($event){return _vm.editarInformacionGeneral(
                'numero_interno',
                _vm.info_general.numero_proceso
              )}},model:{value:(_vm.info_general.numero_proceso),callback:function ($$v) {_vm.$set(_vm.info_general, "numero_proceso", $$v)},expression:"info_general.numero_proceso"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Inicio del proceso")]),_c('v-date-picker',{staticStyle:{"margin-right":"40px","margin-top":"15px"},attrs:{"type":"month","locale":"es-Es","header-color":"#404a7a","min":_vm.moment().format('YYYY-MM-DD')},on:{"change":function($event){return _vm.editarInformacionGeneral(
                'fecha_inicio',
                _vm.info_general.fecha_inicio
              )}},model:{value:(_vm.info_general.fecha_inicio),callback:function ($$v) {_vm.$set(_vm.info_general, "fecha_inicio", $$v)},expression:"info_general.fecha_inicio"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Finalización del proceso")]),_c('v-date-picker',{staticStyle:{"margin-top":"15px","color-text":"white"},attrs:{"min":_vm.info_general.fecha_inicio,"disabled":_vm.info_general.fecha_inicio == '',"type":"month","locale":"es-Es","header-color":"#404a7a"},on:{"change":function($event){return _vm.editarInformacionGeneral('fecha_fin', _vm.info_general.fecha_fin)}},model:{value:(_vm.info_general.fecha_fin),callback:function ($$v) {_vm.$set(_vm.info_general, "fecha_fin", $$v)},expression:"info_general.fecha_fin"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Justificación de la compra"},on:{"change":function($event){return _vm.editarInformacionGeneral(
                'justificacion',
                _vm.info_general.justificacion_compra
              )}},model:{value:(_vm.info_general.justificacion_compra),callback:function ($$v) {_vm.$set(_vm.info_general, "justificacion_compra", $$v)},expression:"info_general.justificacion_compra"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-5",attrs:{"cols":"12"}},[_c('DataTable',{attrs:{"headers":_vm.headers,"items":_vm.lista_administradores,"tiene_paginacion":false}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }