<template>
  <section class="backgroundColor">
    <v-row class="pt-5">
      <v-col cols="12" sm="6">
        <v-autocomplete
          v-model="id_tipo_documento"
          :items="listadoTipoDocumentoFilter"
          item-text="nombre"
          item-value="id"
          outlined 
          label="Tipo de Documento *"
          :error-messages="TipoDocumentoErrorMessage"
          @input="$v.id_tipo_documento.$touch()"
          @blur="$v.id_tipo_documento.$touch()"
        />
      </v-col>
    </v-row>
    <v-row class="pt-5" no-gutters>
      <v-col cols="12" sm="6">
        <v-file-input
          outlined
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
          label="Bases o Términos de Referencia *"
          truncate-length="15"
          v-model="documento"
          :error-messages="documentoErrorMessage"
          @input="$v.documento.$touch()"
          @blur="$v.documento.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          :loading="loading"
          color="secondary"
          :block="$vuetify.breakpoint.xs ? true : false"
          class="bgWithe--text"
          @click="guardarDocumento()"
          >Guardar</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <DataTable
          :headers="headers"
          :items="lista_documentos"
          @paginar="paginarFtn"
          v-models:pagina="lista_documentos_paac.page"
          v-models:select="lista_documentos_paac.per_page"
          :total_registros="lista_documentos_paac.total_rows"
        >
          <template v-slot:[`item.nombre_documento`]="{ item }">
            {{item.nombre_documento.split('-')[0]}}
          </template>
          <template v-slot:[`item.fecha_carga`]="{ item }">
            {{ moment(item.fecha_carga).format('DD/MM/YYYY') }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="descargarDocumentro(item)"               >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Descargar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="eliminarDocumento(item.id)"                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
           
          </template>
        </DataTable>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import DataTable from "@/components/DataTableComponent.vue";
import { mapState, mapActions } from "vuex"
import { required } from "vuelidate/lib/validators";
import PaacServices from '../../../services/Paac.services';

const file_type_validation = (value, vm) => {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
};

const fileMaxSize = (value) => {
  if (value == null) {
    return true;
  }
  const fileSize = value.size / 1024;
  if (fileSize > 5000) {
    return false;
  }
  return true;
};

export default {
  name: "documentosProceso",
  props: ['id'],
  components: {
    DataTable,
  },
  data: () => ({
    loading: false,
    id_tipo_documento: null,
    documento: null,
    headers: [
      {
        text: "Documento",
        align: "center",
        sortable: true,
        value: "nombre_documento",
      },
      {
        text: "Tipo de documento",
        align: "center",
        sortable: true,
        value: "nombre_tipo_documento",
      },
      {
        text: "Fecha de carga",
        align: "center",
        sortable: true,
        value: "fecha_carga",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: true,
        value: "actions",
      },
    ],
  }),
  validations: {
    id_tipo_documento: {
      required,
    },
    documento: {
      required,
      file_type_validation,
      fileMaxSize,
    },
  },
  computed: {
    ...mapState("procesoCompra", [
      "lista_tipos_documentos",
      "lista_documentos",
      "lista_documentos_paac",
    ]),
    TipoDocumentoErrorMessage() {
      const errors = [];
      if (!this.$v.id_tipo_documento.$dirty) return errors;
      !this.$v.id_tipo_documento.required &&
        errors.push("Debe seleccionar tipo de documento");
      return errors;
    },
    documentoErrorMessage() {
      const errors = [];
      if (!this.$v.documento.$dirty) return errors;
      !this.$v.documento.required && errors.push("Debe cargar un documento");
      !this.$v.documento.file_type_validation &&
        errors.push("El archivo debe tener extensión .pdf");
      !this.$v.documento.fileMaxSize &&
        errors.push("El tamaño del archivo no debe ser mayor a 512 KB");
      return errors;
    },
    listadoTipoDocumentoFilter() {
      return this.lista_tipos_documentos.filter((type) => {
        return !this.lista_documentos.some(
          (item) => item.id_tipo_documento === type.id && type.id === 1
        );
      });
    },
  },
  methods: {
    ...mapActions("procesoCompra", [
      "getListaDocumentosProcesoCompra",
      "getDocumentosProcesoCompra",
      "postDocumentoProcesoCompra",
      "deleteDocumentoProcesoCompra",
      "getDocumentosProcesoCompra",
    ]),
    clean(){
      this.id_tipo_documento = null
      this.documento = null
    },
    async descargarDocumentro(item){
      const response = await PaacServices.downloadDocumentoProcesoCompra(item.ruta_documento)
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", `${item.nombre_documento}`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    async paginarFtn(filter = {}){
      const {cantidad_por_pagina, pagina} = filter;

      const filtros = {
        pagination: true,
        page: pagina,
        per_page: cantidad_por_pagina
      }

      const id_proceso_compra = this.id

      await this.getDocumentosProcesoCompra({id_proceso_compra, filtros});
    },
    async getDocumentos(id_proceso_compra){
      const filtros = {
        pagination: true,
        page: 1,
        per_page: 10
      }
      await this.getDocumentosProcesoCompra({id_proceso_compra, filtros})
    },
    async guardarDocumento(){
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true
        let data = {
          id: this.id,
          params: {
            id_tipo_documento: this.id_tipo_documento,
            documento: this.documento,
          }
        }
        const response = await this.postDocumentoProcesoCompra(data)
        if(response.status === 201){
          this.temporalAlert({
            show: true,
            message: response.data.message,
            type: 'success'
          })
          this.clean()
          this.$v.$reset()
          await this.getDocumentos(this.id)
        }else{
          this.temporalAlert({
            show: true,
            message: response.data,
            type: 'success'
          })
        }
        this.loading = false
      }
    },
    async eliminarDocumento(id){
      const response = await this.deleteDocumentoProcesoCompra(id)
      if(response.status === 204){
        this.temporalAlert({
          show: true,
          message: 'Documento eliminado correctamente',
          type: 'success'
        })
        await this.getDocumentos(this.id)
      }else{
        this.temporalAlert({
          show: true,
          message: response.data,
          type: 'success'
        })
      }
    },
  },
  async created(){
    await this.getListaDocumentosProcesoCompra()
    await this.getDocumentos(this.id)
  },
};
</script>

<style scoped>
.backgroundColor {
  background-color: #f4f7fd;
}
</style>
