<template>
  <section class="pb-10">
    <!--
      Muestra el numero de participantes cuando el proceso ya esta publicado
    -->
    <div v-if="info_general.id_seguimiento_proceso != 1">
      <v-row>
        <v-col cols="2" class="d-flex justify-center pb-0">
          <p class="text-h3 secondary--text">{{ items.length }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="d-flex justify-center pt-0">
          <p class="secondary--text total-participantes">Total Participantes</p>
        </v-col>
      </v-row>
    </div>
    <!-- 
      Botón para invitar proveedores solo se mostrara antes de publicar el proceso
      y unicamente cuando el tipo de proceso sea libre gestión o compra directa

      id_seguimiento_proceso = 1 -> proceso en estado de borrador
      id_forma_contratacion  = 2 -> libre gestión
      id_forma_contratacion  = 4 -> compra directa
    -->
    <v-row
      v-if="
        info_general.id_seguimiento_proceso == 1 &&
        (info_general.id_forma_contratacion == 2 ||
          info_general.id_forma_contratacion == 4)
      "
    >
      <v-col cols="12">
        <v-btn
          color="secondary"
          class="bgWhite--text"
          :block="$vuetify.breakpoint.xs ? true : false"
          @click="showModalInvitar = true"
        >
          Invitar
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="items" hide-default-footer>
          <!-- Template para valoraciones -->
          <template v-slot:[`item.ponderacion`]="{ item }">
            <v-rating
              half-increments
              :value="Number(item.ponderacion)"
              readonly
              color="secondary"
              dense
            ></v-rating>
          </template>

          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon>
              <v-icon color="secondary" @click="verParticipante(item)"
                >mdi-eye</v-icon
              >
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Modal para ver la información de los participantes -->
    <app-dialog
      :max-width="1200"
      v-model="showModalVer"
      title="Oferta presentada"
    >
      <template #content>
        <InformacionProvedor :providerData="proveedor" />
      </template>
      <template #actions>
        <div class="mt-3">
          <v-btn
            color="secondary"
            @click="showModalVer = false"
            class="mr-4"
            outlined
          >
            Cerrar
          </v-btn>
        </div>
      </template>
    </app-dialog>

    <app-dialog
      :max-width="1200"
      v-model="showModalInvitar"
      title="Invitar participante"
    >
      <template #content>
        <div>
          <p>Digite el nombre del proveedor que desea invitar al proceso</p>
        </div>
        <!-- Autocomplete para buscar proveedores -->
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              clearable
              :items="lista_proveedores"
              outlined
              item-text="nombre_comercial"
              return-object
              :search-input.sync="nombre_comercial_model"
              label="Nombre comercial"
              @keyup="getListadoProveedores"
              v-model="proveedor_model"
            />
          </v-col>
        </v-row>

        <v-row v-if="proveedor_model != null">
          <v-col cols="12">
            <InformacionProvedor :providerData="proveedor_model" />
          </v-col>
        </v-row>
      </template>

      <template #actions>
        <div class="mt-3">
          <v-btn
            color="secondary"
            @click="showModalInvitar = false"
            class="mr-4"
            outlined
          >
            Cerrar
          </v-btn>
          <v-btn
            v-if="proveedor_model != null"
            color="secondary"
            @click="enviarInvitacion"
            outlined
            :loading="loadingInvitacion"
          >
            Invitar
          </v-btn>
        </div>
      </template>
    </app-dialog>
  </section>
</template>
<script>
import DataTable from "@/components/DataTableComponent.vue";
import InformacionProvedor from "@/components/InfomacionProveedorComponent.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "InvitarParticipanteComponent",
  components: {
    DataTable,
    InformacionProvedor,
  },
  data() {
    return {
      loadingInvitacion: false,
      showModalVer: false,
      showModalInvitar: false,
      proveedor: {
        nombre: "Juan Jose Menendez",
        tipo_contribuyente: "Persona Física",
        documentos: [
          {
            id_tipo_documento: 1,
            numero_documento: "1234567890",
          },
          {
            id_tipo_documento: 2,
            numero_documento: "1234567890",
          },
        ],
        area: "503",
        telefono: "7777-1234",
        email: "proveedor@gmail.com",
        nombre_comercial: "Juan Jose Menendez",
        genero: "Hombre",
        sitio_web: "www.proveedor.com",
        clasificacion_empresa: "Pequeña",
        direccion: {
          pais: "El Salvador",
          departamento: "San Salvador",
          municipio: "San Salvador",
          detalle_direccion: "Colonia Escalon, Calle 1, Casa 2",
        },
      },
      headers: [
        {
          text: "Participante",
          align: "start",
          sortable: true,
          value: "nombre_comercial",
        },
        {
          text: "Valoración",
          align: "start",
          sortable: true,
          value: "ponderacion",
        },
        {
          text: "Acciones",
          align: "center",
          sortable: true,
          value: "acciones",
        },
      ],
      items: [],
    };
  },
  computed: {
    ...mapState("procesoCompra", [
      "lista_proveedores",
      "nombre_comercial",
      "proveedor_invitar",
      "info_general",
    ]),
    nombre_comercial_model: {
      get() {
        return this.nombre_comercial;
      },
      set(value) {
        this.setNombreComercial(value);
      },
    },
    proveedor_model: {
      get() {
        return this.proveedor_invitar;
      },
      set(value) {
        this.setProveedor(value);
      },
    },
  },
  methods: {
    ...mapActions("procesoCompra", [
      "getListadoProveedores",
      "setNombreComercial",
      "setProveedor",
      "invitarProveedor",
    ]),
    async enviarInvitacion() {
      this.loadingInvitacion = true;
      const response = await this.invitarProveedor();

      if (response.status == 200) {
        this.proveedor_model = null;
        this.temporalAlert({
          show: true,
          message: response.data.message,
          type: "success",
        });

        this.showModalInvitar = false;
        this.obtenerParticipantes();
      }
      this.loadingInvitacion = false;
    },
    async verParticipante(item) {
      const response = await this.services.Paac.getMostrarProveedor(item.id);

      if (response.status == 200) {
        this.proveedor = response.data;
        this.showModalVer = true;
      } else {
        this.temporalAlert({
          show: true,
          message: response.data.message,
          type: "error",
        });
      }
    },
    verModalInvitar() {
      this.showModalInvitar = true;
    },
    /**
     * Si el proceso de compra es libre gestión o compra directa y no está
     * publicado llenamos la tabla con los proveedores que ya están invitados
     *
     * Si el proceso ya esta publicado llenamos la tabla con los proveedores que
     * están participando independientemente del tipo de proceso.
     */
    async obtenerParticipantes() {
      let response = null;

      if (
        this.info_general.id_seguimiento_proceso == 1 &&
        (this.info_general.id_forma_contratacion == 2 ||
          this.info_general.id_forma_contratacion == 4)
      ) {
        response = await this.services.Paac.getProveedoresInvitados(
          this.info_general.id
        );
      } else {
        response = await this.services.Paac.getProveedoresParticipantes(
          this.info_general.id
        );
      }

      if (response != null && response.status == 200) {
        this.items = response.data;
      }
    },
  },
  created() {
    this.obtenerParticipantes();
  },
};
</script>
<style lang="scss">
.total-participantes {
  // Borde abajo color secundario
  border-bottom: 5px solid var(--v-secondary-base);
}
</style>
