<template>
  <section class="backgroundColor">
    <v-row>
      <v-col cols="12">
        <DataTable
          :headers="headers"
          :items="lista_fases"
          :tiene_paginacion="false"
          :inhabilitar_paginacion="true"
        >
          <template v-slot:[`item.fecha_hora_inicio`]="{ item }">
            {{
              item.fecha_hora_inicio !== null
                ? moment(item.fecha_hora_inicio).format("DD/MM/YYYY hh:mm A")
                : ""
            }}
          </template>
          <template v-slot:[`item.fecha_hora_fin`]="{ item }">
            {{
              item.fecha_hora_fin !== null
                ? moment(item.fecha_hora_fin).format("DD/MM/YYYY hh:mm A")
                : ""
            }}
          </template>
          <template v-slot:[`item.cantidad_dias`]="{ item }">
            {{ obtenerCantidadDias(item) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="primary"
              :disabled="statusIcon(item)"
              @click="
                item.id_etapa != 4
                  ? mostrarModal(item)
                  : irConfiguracionRecepcionOfts(item)
              "
            >
              mdi-cog
            </v-icon>
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <!-- Modal detalles -->
    <v-dialog v-model="showDialog" max-width="900px" persistent>
      <v-card height="600" class="pt-2 px-5 pb-10">
        <v-card-title>
          <v-icon
            class="text-h6"
            color="secundary"
            @click="(showDialog = false), clean()"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-title>
          <p class="text-h6 secondary--text">{{ phase_name }}</p>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <vc-date-picker
                v-model="fecha_inicio"
                mode="datetime"
                :popover="config_calendar"
                :readonly="true"
                :min-date="id_phase === 1 ? fecha_actual : fecha_minima"
                :max-date="fecha_maxima"
                :valid-hours="horas_disponibles_inicio"
                @dayclick="horasDisponibles(1)"
              >
                <template v-slot="{ inputEvents }">
                  <v-text-field
                    :disabled="id_phase !== 1 || disableFechas == true"
                    :value="getFechaInicio"
                    class="border roundend focus:outline-none focus:border-blue-300"
                    label="Fecha y hora de Inicio"
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-on="inputEvents"
                    :error-messages="fechaInicioErrorMessage"
                    @input="$v.fecha_inicio.$touch()"
                    @blur="$v.fecha_inicio.$touch()"
                  />
                </template>
              </vc-date-picker>
            </v-col>
            <v-col cols="12" md="6">
              <vc-date-picker
                v-model="fecha_fin"
                mode="dateTime"
                locale="es"
                :popover="config_calendar"
                :min-date="getMinDate"
                :max-date="fecha_maxima"
                :valid-hours="horas_disponibles_fin"
              >
                <template v-slot="{ inputEvents }">
                  <v-text-field
                    :value="getFechaFin"
                    :disabled="disableFechas == true"
                    class="border roundend focus:outline-none focus:border-blue-300"
                    label="Fecha y hora de Fin"
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-on="inputEvents"
                    :error-messages="fechaFinErrorMessage"
                    @input="$v.fecha_fin.$touch()"
                    @blur="$v.fecha_fin.$touch()"
                  />
                </template>
              </vc-date-picker>
            </v-col>
          </v-row>
          <v-file-input
            v-if="id_phase === 1"
            outlined
            accept=".pdf"
            prepend-icon=""
            prepend-inner-icon="mdi-paperclip"
            label="Adjuntar Documento *"
            truncate-length="15"
            v-model="documento"
            :error-messages="documentoErrorMessage"
            @input="$v.documento.$touch()"
            @blur="$v.documento.$touch()"
            @change="isDocUpdate = true"
          >
            <template v-if="editMode && documento !== null" v-slot:append>
              <v-icon @click="descargarDocumento" color="green">
                mdi-file-download
              </v-icon>
            </template>
          </v-file-input>
          <v-textarea
            v-show="id_phase !== 9"
            :label="id_phase === 1 ? 'Observación *' : 'Observación'"
            outlined
            v-model="observacion"
            :error-messages="id_phase === 1 ? observacionErrorMessage : ''"
            @input="$v.observacion.$touch()"
            @blur="$v.observacion.$touch()"
          />
        </v-card-text>
        <v-card-actions class="mt-5">
          <v-btn
            :loading="loading"
            color="secondary"
            @click="editMode ? actualizarFase() : guardarFase()"
            class="mr-4 bgWhite--text"
          >
            {{ btnText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { requiredIf } from "vuelidate/lib/validators";
import moment from "moment";
import DataTable from "@/components/DataTableComponent.vue";
import PaacServices from "../../../services/Paac.services";

const file_type_validation = (value, vm) => {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
};

const fileMaxSize = (value) => {
  if (value == null) {
    return true;
  }
  const fileSize = value.size / 1024;
  if (fileSize > 512) {
    return false;
  }
  return true;
};

function dateValidation() {
  return this.fecha_inicio < this.fecha_fin || this.disableFechas == true;
}

export default {
  name: "documentosProceso",
  props: ["id"],
  components: {
    DataTable,
  },
  data: () => ({
    id_etapa: null,
    id_phase: null,
    phase_name: null,
    showDialog: false,
    disableFechas: false,
    fecha_actual: null,
    fecha_minima: null,
    fecha_maxima: null,
    horas_disponibles_inicio: null,
    horas_disponibles_fin: null,
    fecha_inicio: null,
    fecha_fin: null,
    documento: null,
    observacion: null,
    document_name: null,
    document_path: null,
    loading: false,
    editMode: false,
    isDocUpdate: false,
    id_processo: null,
    config_calendar: {
      visibility: "click",
    },
    headers: [
      {
        text: "Etapas",
        align: "center",
        sortable: true,
        value: "nombre",
      },
      {
        text: "Fecha de inicio",
        align: "center",
        sortable: true,
        value: "fecha_hora_inicio",
      },
      {
        text: "Fecha de fin",
        align: "center",
        sortable: true,
        value: "fecha_hora_fin",
      },
      {
        text: "Cantidad de días",
        align: "center",
        sortable: true,
        value: "cantidad_dias",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: true,
        value: "actions",
      },
    ],
  }),
  validations: {
    fecha_inicio: {
      required: requiredIf(function () {
        return this.showDialog;
      }),
      dateValidation,
    },
    fecha_fin: {
      required: requiredIf(function () {
        return this.showDialog;
      }),
    },
    documento: {
      required: requiredIf(function () {
        return this.id_phase === 1 && !this.editMode ? true : false;
      }),
      file_type_validation,
      fileMaxSize,
    },
    observacion: {
      required: requiredIf(function () {
        return this.id_phase === 1 ? this.showDialog : false;
      }),
    },
  },
  computed: {
    ...mapState("procesoCompra", ["lista_fases"]),
    fechaInicioErrorMessage() {
      const errors = [];
      if (!this.$v.fecha_inicio.$dirty) return errors;
      !this.$v.fecha_inicio.required &&
        errors.push("Debe seleccionar fecha de inicio");
      !this.$v.fecha_inicio.dateValidation &&
        errors.push("La fecha de inicio debe ser menor a la fecha de fin");
      return errors;
    },
    fechaFinErrorMessage() {
      const errors = [];
      if (!this.$v.fecha_fin.$dirty) return errors;
      !this.$v.fecha_fin.required &&
        errors.push("Debe seleccionar fecha de finalización");
      return errors;
    },
    documentoErrorMessage() {
      const errors = [];
      if (!this.$v.documento.$dirty) return errors;
      !this.$v.documento.required && errors.push("Debe cargar un documento");
      !this.$v.documento.file_type_validation &&
        errors.push("El archivo debe tener extensión .pdf");
      !this.$v.documento.fileMaxSize &&
        errors.push("El tamaño del archivo no debe ser mayor a 512 KB");
      return errors;
    },
    observacionErrorMessage() {
      const errors = [];
      if (!this.$v.observacion.$dirty) return errors;
      !this.$v.observacion.required && errors.push("Campo requerido");
      return errors;
    },
    getFechaInicio() {
      return this.fecha_inicio
        ? moment(this.fecha_inicio).format("DD/MM/YYYY hh:mm A")
        : "";
    },
    getFechaFin() {
      return this.fecha_fin
        ? moment(this.fecha_fin).format("DD/MM/YYYY hh:mm A")
        : "";
    },
    getMinDate() {
      return this.fecha_inicio
        ? moment(this.fecha_inicio).format("YYYY-MM-DD HH:mm")
        : "";
    },

    btnText() {
      return this.id_phase !== 9 ? "Guardar" : "Continuar";
    },
  },
  methods: {
    ...mapActions("procesoCompra", [
      "getFasesProcesoCompra",
      "updateFasesProcesoCompra",
      "postDocumentoFasesProcesoCompra",
    ]),
    ...mapMutations("procesoCompra", ["setFechaInicioAnterior", "setFechaFin"]),
    clean() {
      this.fecha_inicio = null;
      this.fecha_fin = null;
      (this.documento = null), (this.observacion = null);
      this.$v.fecha_inicio.$reset();
      this.$v.fecha_fin.$reset();
      this.$v.documento.$reset();
      this.$v.observacion.$reset();
    },
    horasDisponibles(n) {
      switch (n) {
        case 1:
          this.horas_disponibles_inicio = [];
          if (this.fecha_minima !== this.fecha_inicio) {
            for (let i = 0; i <= 23; i++) {
              this.horas_disponibles_inicio.push(Number(i));
            }
          } else {
            const hora = this.fecha_minima.split(" ")[1].split(":")[0];
            for (let i = Number(hora); i <= 23; i++) {
              this.horas_disponibles_inicio.push(Number(i));
            }
            this.horas_disponibles_inicio.push(Number(0));
          }
          break;
        case 2:
          this.horas_disponibles_fin = [];
          if (
            moment(this.fecha_inicio).format("YYYY-MM-DD") !==
            moment(this.fecha_fin).format("YYYY-MM-DD")
          ) {
            for (let i = 0; i <= 23; i++) {
              this.horas_disponibles_fin.push(Number(i));
            }
          } else {
            const hora = moment(this.fecha_inicio)
              .format("YYYY-MM-DD HH:mm")
              .split(" ")[1]
              .split(":")[0];
            const min = moment(this.fecha_inicio)
              .format("YYYY-MM-DD HH:mm")
              .split(" ")[1]
              .split(":")[1];
            if (min > 30) {
              for (let i = Number(hora) + 1; i <= 23; i++) {
                this.horas_disponibles_fin.push(Number(i));
              }
            } else {
              for (let i = Number(hora); i <= 23; i++) {
                this.horas_disponibles_fin.push(Number(i));
              }
            }
            this.horas_disponibles_fin.push(Number(0));
          }
          break;
        default:
          break;
      }
    },
    obtenerFechaMinima(item) {
      const i = this.lista_fases.indexOf(item);
      if (i !== 0) {
        if (this.lista_fases[i].fecha_hora_inicio !== null) {
          const date = new Date(this.lista_fases[i].fecha_hora_fin);
          date.setDate(date.getDate());
          this.fecha_minima = moment(this.lista_fases[i].fecha_hora_fin).format(
            "YYYY-MM-DD HH:mm"
          );
          const hora = this.fecha_minima.split(" ")[1].split(":")[0];
          this.horas_disponibles_inicio = [];
          if (
            this.fecha_minima !==
            moment(this.fecha_inicio).format("YYYY-MM-DD HH:mm")
          ) {
            for (let i = 0; i <= 23; i++) {
              this.horas_disponibles_inicio.push(Number(i));
            }
          } else {
            for (let i = Number(hora); i <= 23; i++) {
              this.horas_disponibles_inicio.push(Number(i));
            }
            this.horas_disponibles_inicio.push(Number(0));
          }
        }
      }
    },
    obtenerFechaMaxima(item) {
      const i = this.lista_fases.indexOf(item);
      if (i !== 4) {
        if (this.lista_fases[i].fecha_hora_inicio !== null) {
          const date = new Date(this.lista_fases[i].fecha_hora_inicio);
          date.setDate(date.getDate());
          this.fecha_maxima = moment(
            this.lista_fases[i].fecha_hora_inicio
          ).format("YYYY-MM-DD HH:mm");
          this.horas_disponibles_fin = [];
          if (
            moment(this.fecha_inicio).format("YYYY-MM-DD HH:mm") !==
            moment(this.fecha_fin).format("YYYY-MM-DD HH:mm")
          ) {
            for (let i = 0; i <= 23; i++) {
              this.horas_disponibles_fin.push(Number(i));
            }
          } else {
            const hora = moment(this.fecha_inicio)
              .format("YYYY-MM-DD HH:mm")
              .split(" ")[1]
              .split(":")[0];
            const min = moment(this.fecha_inicio)
              .format("YYYY-MM-DD HH:mm")
              .split(" ")[1]
              .split(":")[1];
            if (min > 30) {
              for (let i = Number(hora) + 1; i <= 23; i++) {
                this.horas_disponibles_fin.push(Number(i));
              }
            } else {
              for (let i = Number(hora); i <= 23; i++) {
                this.horas_disponibles_fin.push(Number(i));
              }
            }
            this.horas_disponibles_fin.push(Number(0));
          }
        }
      }
    },
    getCurrentDay() {
      const date = new Date(Date.now());
      this.fecha_actual = moment(date).format("YYYY-MM-DD HH:mm");
    },
    obtenerCantidadDias(item) {
      let f_inicio;
      let f_fin;
      let suma = 0;
      if (item.fecha_hora_inicio !== null && item.fecha_hora_fin !== null) {
        f_inicio = Number(item.fecha_hora_inicio.split("-")[2].split("T")[0]);
        f_fin = Number(item.fecha_hora_fin.split("-")[2].split("T")[0]);
        if (f_inicio === f_fin) return 1;
        for (let i = f_inicio; i < f_fin; i++) {
          suma++;
        }
        return suma;
      }
      return "";
    },
    statusIcon(item) {
      const i = this.lista_fases.indexOf(item);
      if (i === 0) return false;
      if (
        this.lista_fases[i - 1].fecha_hora_inicio === null &&
        item.fecha_hora_inicio === null
      )
        return true;
    },
    async mostrarModal(item) {
      this.id_etapa = item.id;
      this.id_phase = item.id_etapa;
      this.phase_name = item.nombre;
      this.disableFechas = false;
      if (this.id_phase === 1) {
        this.document_path = item.ruta_documento;
        this.document_name = item.nombre_documento;
        if (this.document_path) {
          await this.cargarDocumento();
        }
      } else {
        this.documento = null;
      }
      this.fecha_minima = null;
      this.obtenerFechaMinima(item);
      if (item.fecha_hora_inicio !== null && item.fecha_hora_fin !== null) {
        this.disableFechas = true;
        this.fecha_inicio = null;
        this.fecha_fin = null;
        this.fecha_inicio = moment(item.fecha_hora_inicio).format(
          "YYYY-MM-DD HH:mm"
        );
        this.fecha_fin = moment(item.fecha_hora_fin).format("YYYY-MM-DD HH:mm");
        if (this.id_phase !== 1) this.obtenerFechaMaxima(item);
        this.observacion = item.observacion;
        this.editMode = true;
      } else {
        this.editMode = false;

        if (this.id_phase !== 1) {
          let currentIndex = this.lista_fases.indexOf(item);
          let fechaEtapaAnterior =
            this.lista_fases[currentIndex - 1].fecha_hora_fin;
          this.fecha_inicio = moment(fechaEtapaAnterior).add(1, "minutes");
        }
      }
      this.showDialog = true;
    },
    async cargarDocumento() {
      const response = await PaacServices.downloadDocumentoProcesoCompra(
        this.document_path
      );
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const blob = new Blob([response], {
          type: "application/pdf",
        });
        let nombre = this.document_name.split("-")[0];
        this.documento = new File([blob], `${nombre}.pdf`, {
          type: "application/pdf",
        });
      }
    },
    async descargarDocumento() {
      const response = await PaacServices.downloadDocumentoProcesoCompra(
        this.document_path
      );
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", `${this.document_name}`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    async guardarFase() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const data = {
          id: this.id_etapa,
          params: {
            id_proceso_compra:this.id_process,
            fecha_hora_inicio: this.fecha_inicio,
            fecha_hora_fin: this.fecha_fin,
            observacion: this.observacion,
          },
        };
        const response = await this.updateFasesProcesoCompra(data);
        if (response.status === 200 && this.id_phase === 1) {
          const params = {
            id: this.id_etapa,
            documento: this.documento,
          };
          if (this.isDocUpdate === true) {
            const response = await this.postDocumentoFasesProcesoCompra(params);
            if (response.status === 201 || response.status === 200) {
              this.temporalAlert({
                show: true,
                message: response.data.message,
                type: "success",
              });
            } else {
              this.temporalAlert({
                show: true,
                message: response.data.message,
                type: "error",
              });
            }
          }
        } else {
          if (response.status === 200 || response.status === 201) {
            this.temporalAlert({
              show: true,
              message: response.data.message,
              type: "success",
            });
          } else {
            this.temporalAlert({
              show: true,
              message: response.data.message,
              type: "error",
            });
            
          }
        }

        this.loading = false;
        this.showDialog = false;
        this.disableFechas = false;
        await this.getFases(this.id);
        this.clean();
        this.observacion = "";

        if (this.id_phase === 9)
          this.$router.push({
            name: "configuracion-subasta-inversa",
            params: { id_process: this.id_process },
          });
      }
    },
    async actualizarFase() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const data = {
          id: this.id_etapa,
          params: {
            id_proceso_compra:this.id_process,
            fecha_hora_inicio: this.fecha_inicio,
            fecha_hora_fin: this.fecha_fin,
            observacion: this.observacion,
          },
        };
        const response = await this.updateFasesProcesoCompra(data);

        if (response.status === 200 && this.id_phase === 1) {
          if (this.isDocUpdate) {
            if (this.documento !== null) {
              const params = {
                id: this.id_etapa,
                documento: this.documento,
              };
              const response = await this.postDocumentoFasesProcesoCompra(
                params
              );
              if (response.status === 201 || response.status === 200) {
                this.temporalAlert({
                  show: true,
                  message: response.data.message,
                  type: "success",
                });
              } else {
                this.temporalAlert({
                  show: true,
                  message: response.data.message,
                  type: "error",
                });
              }
            }
          } else {
            this.temporalAlert({
              show: true,
              message: response.data.message,
              type: "success",
            });
          }
        } else {
          this.temporalAlert({
            show: true,
            message: response.data.message,
            type: "success",
          });
        }
        this.loading = false;
        this.showDialog = false;
        this.clean();
        this.clean();

        if (this.id_phase === 9)
          this.$router.push({
            name: "configuracion-subasta-inversa",
            params: { id_process: this.id_process },
          });
      }
      this.getFases(this.id);
      this.observacion = "";
    },
    async getFases(id_proceso_compra) {
      await this.getFasesProcesoCompra(id_proceso_compra);
    },
    irConfiguracionRecepcionOfts(item) {
      let currentIndex = this.lista_fases.indexOf(item);
      let fechaEtapaAnterior =
        this.lista_fases[currentIndex - 1].fecha_hora_fin;
      this.fecha_inicio = moment(fechaEtapaAnterior).add(1, "minutes");
      this.setFechaInicioAnterior(this.fecha_inicio);
      this.setFechaFin(moment(item.fecha_hora_fin))
      this.$router.push({
        name: "recepcion-oferta",
        params: {
          id_proceso_compra: this.id_process,
          etapa: item.id,
        },
      });
    },
  },
  watch: {
    showDialog(val) {
      if (!val) {
        this.clean();
      }
    },
  },
  async created() {
    this.id_process = this.$route.params.id;

    this.getCurrentDay();
    await this.getFases(this.id);
  },
};
</script>

<style scoped>
.backgroundColor {
  background-color: #f4f7fd;
}
.cursor {
  cursor: pointer;
}
</style>
